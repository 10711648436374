import { createRef, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

function App () {
  const [word, setWord] = useState('')
  const [l10n, setL10n] = useState('')
  const wordRef = createRef()
  const l10nRef = createRef()

  const handleSubmit = e => {
    e.preventDefault()
    setWord(wordRef.current.value)
    setL10n(l10nRef.current.value)
  }

  const isEnglish = ['', 'en-us'].includes(l10n.toLowerCase())
  let audioSrc = `https://flashcard-assets.meetlabs.id/api/audio/${word}`
  if (!isEnglish) {
    audioSrc += `/l10n/${l10n}`
  }
  return (
    <div className='container-fluid'>
      <form
        onSubmit={handleSubmit}
        className='row row-cols-lg-auto g-3 justify-content-center'
        style={{ marginTop: 20 }}
      >
        <div className='col-12'>
          <div className='input-group'>
            <input
              ref={wordRef}
              type='text'
              className='form-control'
              placeholder='Word'
            />
          </div>
        </div>
        <div className='col-4'>
          <div className='input-group'>
            <input
              ref={l10nRef}
              type='text'
              className='form-control'
              placeholder='Localization'
              style={{ width: 120 }}
            />
          </div>
        </div>
        <div class='col-12'>
          <button type='submit' class='btn btn-primary'>
            Update
          </button>
        </div>
      </form>
      {word && (
        <>
          <div className='row justify-content-center' style={{ marginTop: 10 }}>
            <div className='col-4'>
              <center>
                <audio controls src={audioSrc} />
              </center>
            </div>
          </div>
          {isEnglish && (
            <div
              className='row justify-content-center'
              style={{ marginTop: 10 }}
            >
              <div className='col-4'>
                <center>
                  <img
                    src={`https://flashcard-assets.meetlabs.id/api/images/${word}`}
                    className='img-thumbnail'
                    alt={word}
                  />
                </center>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default App
